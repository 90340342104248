import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const descriptions = {
  de: `Victoria House und die verbundenen Unternehmen sind Teil des Familienunternehmens, welches seit über 23 Jahren Wohnimmobilien entwickelt. Mehr als 3.100 Wohnungen und Häuser wurden bisher realisiert und an Käufer übergeben. Derzeit befinden sich über 2.000 Wohnungen im Bau.`,
  en: `Victoria House and the affiliated companies are part of the family business that has been developing residential properties for over 23 years. More than 3.100 apartments and houses were built and delivered to individual buyers.`,
}

function SEO({ description, lang, meta, title }) {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          allSettings {
            generalSettingsTitle
            generalSettingsDescription
          }
        }
      }
    `
  )

  const { allSettings } = wpgraphql
  const metaDescription = description || descriptions[lang]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${allSettings.generalSettingsTitle}`}
      meta={[
        {
          property: `robots`,
          content: `index, follow`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: allSettings.generalSettingsTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: allSettings.generalSettingsTitle || title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link href="https://www.victoriadom.pl/" hrefLang="pl" rel="alternate" />
      <link
        href="https://victoriawohnungsbau.de/"
        hrefLang="de"
        rel="alternate"
      />
      <link
        href="https://victoriawohnungsbau.de/en/"
        hrefLang="en"
        rel="alternate"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
